// Buttons
.btn {
  @extend .btn-input-shared-styles;
  display: block;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  transform: scale(1);
  &:active {
    transform: scale(0.98);
    box-shadow: none;
  }
  background-color: $c-beta;
  color: #fff;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  &:focus-visible,
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 0.625rem 3rem rgba(#000, 0.2);
  }

  &:focus-visible::before,
  &:focus-visible::after {
    content: '';
    width: 6.25rem;
    height: 6.25rem;
    position: absolute;
    top: 1.6875rem;
    left: 5.125rem;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    animation-name: btn-circle-scale;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    background-color: #fff;
  }

  &:focus-visible::after {
    left: 15.125rem;
  }
}

.btn-shadow {
  box-shadow: 0px 0.625rem 0.9375rem rgba($c-beta, 0.2);
}

.phone {
  outline: none;
  transform: scale(1);
  border-radius: 50%;
  &:focus-visible,
  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 0.625rem 3rem rgba(#000, 0.2);
  }
  &:active {
    transform: scale(0.98);
    box-shadow: none;
  }
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.close-btn {
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: $c-lambda;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  outline: none;
  &:focus-visible,
  &:hover {
    background-color: $c-beta;
    & .close-btn__icon {
      filter: brightness(0) invert(1);
    }
  }
}

.close-btn__icon {
  width: 1rem;
  height: 1rem;
}
