.btn-input-shared-styles {
  width: 100%;
  border: 0;
  padding: 1.1875rem;
  border-radius: 0.25rem;

  &:focus {
    outline: none;
  }
}

.btn__circle {
  width: 6.25rem;
  height: 6.25rem;
  position: absolute;
  top: 1.6875rem;
  left: 5.125rem;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation-name: btn-circle-scale;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  background-color: #fff;
}

.ellipse {
  position: absolute;
  top: 7.1rem;
  right: 0;
}

.container {
  width: 100%;
  min-height: 100%;
  height: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}

.items-vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-beta {
  color: $c-beta;
}

.fw-600 {
  font-weight: 600;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
}

.text__title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 120%;
}

.text__subtitle {
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 120%;
}

.hidden-form {
  display: none;
  &.show {
    display: block;
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
}

.hiding,
.btn.hiding,
.popup-form.hiding {
  opacity: 1;
  animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.hidden,
.btn.hidden,
.popup-form.hidden {
  display: none;
}

.section-divider {
  width: 17.6875rem;
  max-width: 70%;
  height: 0.0625rem;
  margin: 0 auto;
  border: none;
  background-color: $c-beta;
}
