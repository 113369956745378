.section-reasons__reasons {
  padding: 0;
}

.reasons__reason {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $c-theta;
}

.reason__text {
  margin-left: 1.4375rem;
  width: 100%;
}
