@font-face {
  font-family: 'Geometria-bold';
  src: url(../fonts/geometria-bold.ttf) format('truetype');
}

@font-face {
  font-family: 'TT-Firs-neue';
  src: url(../fonts/TTFirsNeue-Regular.ttf) format('truetype');
}

// Stem
@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-Hairline.otf) format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-Thin.otf) format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-ExtraLight.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-ExtraLightItalic.otf) format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-Light.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-LightItalic.otf) format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-Regular.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-Italic.otf) format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-Medium.otf) format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-MediumItalic.otf) format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-Bold.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Stem;
  src: url(../fonts/stem/Stem-BoldItalic.otf) format('opentype');
  font-weight: 700;
  font-style: italic;
}
