.section-discount__content {
  padding: 2.1875rem 1.375rem;
  border-radius: 0 0 1.5625rem 0;
  background-color: $c-epsilon;
  position: relative;
}

.discount-banner,
.discount-subanner {
  margin: 0;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 120%;
  text-align: center;
}

.discount-banner {
  padding: 0.625rem 0.6rem;
  border: 0.125rem solid $c-beta;
  border-radius: 0.25rem 0.6875rem;
  color: $c-beta;
}

.discount-subanner {
  margin-top: 0.75rem;
  color: $c-alpha;
}

.content__block {
  text-align: center;
  margin: 2.3125rem 0;
}

.divider {
  // margin: 2rem 0;
  height: 0.0625rem;
  position: relative;
  background-color: rgba($c-zeta, 0.3);
}

.divider__circle {
  width: 2.4375rem;
  height: 2.4375rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $c-beta;
}

.block__subtitle {
  margin: 2.4375rem 0 0 0;
  font-weight: normal;
  font-size: 0.625rem;
  line-height: 120%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $c-eta;
}

.block__title {
  margin: 0.375rem 0;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 120%;
  text-align: center;
  color: #000000;
}

.block__price {
  margin-top: 0.9375rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price__crossed,
.price__discount {
  margin: 0;
}

.price__crossed {
  margin-right: 0.75rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 120%;
  color: $c-eta;
  position: relative;
  &::after {
    content: '';
    width: 3.321875rem;
    height: 0.0625rem;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transform: rotate(11.94deg);
    background-color: $c-alpha;
  }
}

.price__discount {
  padding: 0.375rem 0.8125rem;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 120%;
  color: #ffffff;
  background-color: $c-alpha;
  box-shadow: 0 0.625rem 0.9375rem rgba($c-alpha, 0.17);
  border-radius: 3.75rem;
}
