.popup-form {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  animation: fadeIn 0.3s ease-in;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#000, 0.5);
    z-index: -1;
  }
}

.popup-form__content {
  margin-top: 4.375rem;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 1.5625rem 0.1875rem 0.1875rem 0.1875rem;
  background-color: #fff;
  position: relative;
  & .content__title {
    margin-top: 1.5625rem;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 120%;
    margin-bottom: 0.5rem;
  }
  & .content__info {
    margin-bottom: 1.25rem;
    font-weight: normal;
    font-size: 0.8125rem;
    line-height: 120%;
    color: $c-kappa;
  }
  & .form-main-alt {
    margin-top: 0;
  }
}
