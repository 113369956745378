@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import 'mixin';
@import 'normalize';
@import 'config';
@import 'fonts';
@import 'base/animations';
@import 'base/utility';
@import 'base/general';
@import 'base/buttons';
@import 'base/forms';
@import 'sections/header';
@import 'sections/section-header';
@import 'sections/section-perks';
@import 'sections/section-discount';
@import 'sections/section-reasons';
@import 'sections/section-about';
@import 'sections/section-circle';
@import 'sections/section-steps';
@import 'sections/footer';
@import 'sections/section-popup';

// @import 'mobile';
