html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

body {
  min-height: 100%;
  font-family: Stem, sans-serif;
  max-width: 100%;
  overflow-x: hidden;

  @include fadeIn(0);
}

main {
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}

@media only screen and (max-width: 365px) {
  html {
    font-size: 4vw;
  }
}

// IOS support to prevent it from zooming when User clicks on the inputs

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    background: #fff;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
