@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-500%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes btn-circle-scale {
  to {
    transform: translate(-50%, -50%) scale(3);
    opacity: 0;
  }
}
