.section-header {
  padding-top: 1.3125rem;
  background-color: $c-alpha;
  color: set-text-color($c-alpha);
}

// Discount
.discount {
  height: 6.8125rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.discount__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 6.8125rem;
  height: 6.8125rem;
  padding-bottom: 1rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: $c-beta;
  z-index: 1;
  @include fadeIn(2);
}

.circle__text {
  font-size: 0.72001875rem;
  display: flex;
  flex-direction: column;
  transform: rotate(10deg);
}

.text__bigger {
  font-size: 2rem;
  line-height: 120%;
}

.discount__text-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.8125rem;
  border-radius: 4.625rem;
  background-color: #540195;
  @include slideRight(2);
}

.text-container__text {
  font-size: 1.25rem;
  // margin-left: auto;
  // margin-right: 1rem;
  margin: 0 auto;
  transform: translateX(3.40625rem);

  @include fadeIn(2.5);
}
// /Discount

// Section header content
.section-header__content {
  padding-top: 1.6875rem;
  padding-bottom: 8.4rem;
  position: relative;
  z-index: 2;
}

.content__title {
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 120%;

  @include fadeIn(1);
}

.content__subtitle {
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 120%;

  @include fadeIn(1.5);
}

.select-wrapper {
  position: relative;
  margin-bottom: 0.75rem;
}

.select-arrow-down {
  background-image: url(../img/svgs/triangle-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
}
// /Section header content
