@mixin fadeIn($delay) {
  $delay: $delay - 0.5;

  @if $delay < 0 {
    $delay: 0;
  } @else {
    $delay: unquote($delay + 's');
  }

  @supports (animation-fill-mode: forwards) {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.2s;
    animation-delay: $delay;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
}

@mixin slideRight($delay) {
  $delay: $delay - 0.7;

  @if $delay < 0 {
    $delay: 0;
  } @else {
    $delay: unquote($delay + 's');
  }

  @debug $delay;

  @supports (animation-fill-mode: forwards) {
    transform: translateX(-100%);
    animation-name: slideRight;
    animation-duration: 1s;
    animation-delay: $delay;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
}
