.form-main {
  margin-top: 2.5rem;
}

.form-main__field {
  @extend .btn-input-shared-styles;
  font-weight: 300;
  line-height: 120%;
  border: 0.125rem solid transparent;
  background-color: $c-delta;
  color: set-text-color($c-delta);
  -webkit-appearance: none;
  &:active,
  &:focus {
    background-color: $c-delta;
  }
  &::placeholder {
    color: rgba(#fff, 0.5);
  }
  &:not(select) {
    margin-bottom: 0.75rem;
  }
  &:focus-visible,
  &:hover {
    border: 0.125rem solid $c-beta;
  }
}

.form-main__submit {
  margin-top: 0.23rem;
  margin-bottom: 0.75rem;
}

.form-main-alt {
  @extend .form-main;
  & select.form-main__field {
    color: $c-kappa;
    &:valid {
      color: $c-theta;
    }
  }
  & .form-main__field {
    border: 0.125rem solid $c-iota;
    background-color: transparent;
    color: $c-theta;
    &::placeholder {
      color: $c-kappa;
    }
    &:focus-visible,
    &:hover {
      border: 0.125rem solid $c-beta;
    }
  }

  & .select-arrow-down {
    background-image: url(../img/svgs/triangle-down-alt.svg);
    height: 0.8rem;
    width: 0.8rem;
    right: 1.1875rem;
  }
}
