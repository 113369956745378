.section-steps__steps {
  list-style-type: none;
  padding: 0;
  margin-top: 1.875rem;
}

.steps__step {
  display: flex;
}

.step__icon {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $c-beta;
  color: #fff;
}

.step__text {
  margin-top: 0;
  margin-left: 1.625rem;
}

.text__title {
  margin: 0;
}

.text__subtitle {
  margin-top: 0.3125rem;
}
