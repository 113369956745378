.section-perks {
  margin-top: -8.3rem;
}

.perks {
  list-style-image: url(../img/svgs/check.svg);
  list-style-position: inside;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0.4375rem 6.25rem rgba(0, 0, 0, 0.09);
  border-radius: 1.5625rem 0 0 0;
  background-color: #fff;
  position: relative;
  z-index: 2;
  font-weight: 300;
  font-size: 15px;
  line-height: 120%;
}

.perks__perk {
  display: flex;
  align-items: center;
  & p {
    margin: 0.5rem 0;
  }
}

.perk__icon {
  padding-right: 1rem;
}
